const CONSTANTS: any = {
    RENEWING: "RENEWING",
    PENDING: "PENDING",
    REGISTRED: "APPROVED",
    ASCENDING: "ASCENDING",
    DESCENDING: "DESCENDING",
    ASC: "ASC",
    DESC: "DESC",
    DEFAULT: "DEFAULT",
    EXPIRY_ASCENDING: "EXPIRY_ASCENDING",
    EXPIRY_DESCENDING: "EXPIRY_DESCENDING",
    EXPIRY_DATE_ASCENDING: "EXPIRY DATE ASCENDING",
    EXPIRY_DATE_DESCENDING: "EXPIRY DATE DESCENDING",
    DEACTIVATED: "DEACTIVATED",
    REJECTED: "REJECTED",
    CERTIFIED: "CERTIFIED",
    AWARDED: "AWARDED",
    BULKDOCUMENT: "BULKDOCUMENT",
    INTERNATIONAL: "INTERNATIONAL",
    DOMESTIC: "DOMESTIC",
    INIT_AWARD_YEAR: 2024,
    ARCHIVED_DATE_NOT_FOUND: "no-archived-date-found",
    AUTHORIZERALLOWTOKEN: "UUNUQXV0aG9yaXplckR1bW15VG9rZW4=",
    ALL: "ALL",
    LOCAL_STORE_KEY: "#%$##%$#",
    // Enter Text HTML Title
    ENTER_ALPHANUMERIC_TEXT: "Please enter alphanumeric characters",
    // Regex Patterns
    REGEX_ALLOW_ALPHABHET_SPACE: "^([a-zA-Z])+[a-zA-Z+\\s]+$",
    REGEX_ALLOW_ALPHANUMERIC_SPECIALCHARS: "^[ A-Za-z0-9()[\\]+-*/%]*$",
    REGEX_ALLOW_ALPHANUMERIC: "[a-zA-Z0-9]+",
    REGEX_ALLOW_ALPHANUMERIC_SPACE: "[a-zA-Z0-9\\s]+",
    REGEX_ALLOW_ALPHABET_SPACE_DOT: "[a-zA-Z\\.\\s]+",
    REGEX_ALLOW_WEBSITE:
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
    /*
    string should not start and end with special char,
    string should not start with numbers,
    string should only contains alpha(a-z) numeric (0-9) and a special chars (space)
 */
    REGEX_ALLOW_ALPHANUMERIC_AND_ONE_TO_TEN_CHAR: "^[a-zA-Z0-9]{1,10}$",
    REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY: "^([a-zA-Z])+[a-zA-Z0-9+\\s]+$",
    REGEX_ALLOW_ALPHANUMERIC_PARTICIPANTID: "^([a-zA-Z0-9])+[a-zA-Z0-9+\\s]+$",
    REGEX_ALLOW_NUMBERS: "\\+?\\d*",
    REGEX_ALLOW_ALPHABHET_SPACE_AND_WASHINGTON_STATE:
        "^(Washington, D.C.|[a-zA-Z][a-zA-Z ]+)$",
    REGEX_RESTRICT_SPECIAL_CHAR_GL: "^[^<>]*$",
    WINDOW: window,
    COMPLETED: "COMPLETED",
    OFFLINE: "Offline",
    ONLINE: "Online",
    PAID: "Paid",
    DOMESTIC_COUNTRY_CODE: "US",
    DOMESTIC_COUNTRY_NAME: "United States",
    // Loader Generic Class Names
    BUTTON_SPINNER: "aui-btn-spinner",
    TABLE_SPINNER: "aui-table-loader",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    REGISTRY_OTHER: "Other",
    REGISTRY: "REGISTRY",
    CORPORATION: "CORPORATION",
    FILE_UPLOAD_SIZE_LIMIT: 31457280,
    PDF_FILE_UPLOAD_ERROR_MSG:
        "Unable to process the file upload. Please upload a pdf file(maximum 30MB). Example: Agreement1.pdf",
    FILE_UPLOAD_ERROR_MSG:
        "Unable to process the file upload, Please consider checking your File Type, File name and File size (maximum 30MB).",
    PAYMENT_ONLINE: "ONLINE",
    PAYMENT_OFFLINE: "OFFLINE",
    EXPORT_FACILITY_TEMPLATE: "2359e4c0-481c-9dbe-7924-c1ebdcc59bfd",
    EXPORT_DASHBOARD_TEMPLATE: "9474dae5-b8c7-4bb6-9e32-6ab8a3b2e825",
    EMS_EXPORT_FACILITY_TEMPLATE: "3f9747f4-8392-11ee-b962-0242ac120002",
    UNAUTHORIZED: "UNAUTHORIZED",
    ALREADY_EXISTS: "ALREADY_EXISTS",
    SESSION_EXPIRED: "SESSION_EXPIRED",
    PERCENTAGE: "Percentage",
    CERTIFICATE_FACILITY_NAME_LIMIT: 100,
    USER_ROLES: {
        EMS_ADMIN: "EMS_ADMIN",
        AHA_ADMIN: "AHA_ADMIN",
        HOSPITAL_ADMIN: "HOSPITAL_ADMIN",
        SUPER_ADMIN: "SUPER_ADMIN",
        REGISTRY_ADMIN: "REGISTRY_ADMIN",
        CORPORATION_ADMIN: "CORPORATION_ADMIN",
    },
    HOSPITAL: "hospital",
    THANKYOU_PAGE: "thankyou-page",
    REVIEWER_REPORT: "Reviewer Report",
    SITE_SUMMARY: "Site Summary",
    HOSPITAL_MAXUSERS: 500,
    SCROLLTO_VALUE: 515,
    SELECTALL_COUNT: 25,
    ROUTES: {
        HOME: "/",
        USER_DASHBOARD: "/userDashboard",
        ADMIN_DASHBOARD: "/admin/dashboard",
        EMSADMIN_DASHBOARD: "/emsadmin/organization-management",
        CORPORATION_DASHBOARD: "/corporation/dashboard",
        REGISTRY_DASHBOARD: "/registry/dashboard",
        HOSPITAL_REGISTRATION: "/organization/register",
        PROGRAMS: "/programs",
        PROGRAM_REGISTRATION: "/program/register",
    },
    PATHS: {
        HOSPITAL_INACTIVE: "hospital-inactive",
        PROGRAM_SELECTION: "program-selection",
        PROGRAM_INACTIVE: "program-inactive",
        AGREEMENT: "agreement",
        PAYMENTSUMMARY: "paymentSummary",
        PAYMENT_STATUS: "paymentStatus",
        DASHBOARD: "dashboard",
    },
    FACILITY_TYPE: {
        REGISTERED: "Registered",
        CERTIFIED: "Certified",
        DEACTIVATED: "Deactivated",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
    },
    UPLOADED_BY: {
        FACILITY: "Organization",
        CORPORATION: "Corporation",
        ADMIN: "AHA Admin",
    },
    PAYMENT_COMPLETE_MSG: "Payment is already made.",
    REGISTRATION_COMPLETED_BY_CORPORATION:
        "Registration already completed by Corporation.",
    PAYMENT_BY_FACILITY: "Payment already made by the organization.",
    REQUEST_ABORTED: "Request aborted",
    NETWORK_ERROR: "NetworkError when attempting to fetch resource.",
    SLEEP_TIME: 1000,
    AGREEMENT_UPLOADED_BY_FACILITY:
        "Agreement already uploaded by the organization.",
    AGREEMENT_UPLOADED_BY_CORPORATION:
        "Agreement already uploaded by the corporation.",
    FORBIDDEN: "FORBIDDEN",
    BAD_REQUEST: "BAD_REQUEST",
    CHART_TYPE: {
        COLUMN: "Column",
        LINE: "Line",
    },
    ALL_VALUE: "All",
    QUARTERLY: "Quarterly",
    ANNUAL: "Annual",
    INTERVAL: {
        QUARTERLY: "QUARTERLY",
        ANNUAL: "ANNUAL",
    },
    EMS_PROGRAM_CATEGORY_ID: 15,
    EMS_PROGRAM_ID: "22",
    HFC_PROGRAM_ID: "25",
    HYPERTENSION_PROGRAM_ID: "16",

    TITLE_DROPDOWN: [
        { label: "Mr.", value: "Mr.", ariaLabel: "Mister" },
        { label: "Mrs.", value: "Mrs.", ariaLabel: "miz-is" },
        { label: "Miss", value: "Miss", ariaLabel: "Miss" },
        { label: "Ms.", value: "Ms.", ariaLabel: "Miss" },
        { label: "Dr.", value: "Dr.", ariaLabel: "Doctor" },
        { label: "Chief", value: "Chief", ariaLabel: "Chief" },
        {
            label: "Asst. Chief",
            value: "Asst. Chief",
            ariaLabel: "Assistant Chief",
        },
        {
            label: "Batt. Chief",
            value: "Batt. Chief",
            ariaLabel: "Battalion Chief",
        },
        { label: "Capt.", value: "Capt.", ariaLabel: "Captain" },
        { label: "Lt.", value: "Lt.", ariaLabel: "Lieutenant" },
    ],

    REGISTRATION: [
        {
            value: "countryName",
            label: "Location",
        },
        {
            value: "streetAddress",
            label: "Street Address",
        },
        {
            value: "cityName",
            label: "City",
        },
        {
            value: "stateName",
            label: "State / Province",
        },
        {
            value: "zipCode",
            label: "Zip Code / Postal Code",
        },
        {
            value: "website",
            label: "Website",
        },
        {
            value: "primaryContactName",
            label: "Primary Contact Name",
        },
        {
            value: "primaryContactPhoneNo",
            label: "Primary Contact Phone Number",
        },
        {
            value: "primaryEmail",
            label: "Primary Contact Email Address",
        },
        {
            value: "signatoryName",
            label: "Signatory Name",
        },
        {
            value: "signatoryEmail",
            label: "Signatory Email Address",
        },
        {
            value: "medicalDirectorName",
            label: "Medical Director Name",
        },
        {
            value: "medicalDirectorEmail",
            label: "Medical Director Email Address",
        },
        {
            value: "erhName",
            label: "Electronic Health Record",
        },
    ],
    APPROVE: "Approve",
    EXPORT: "Export",
    REMOVE: "Remove",
    DEACTIVATE: "Deactivate",
    ACTIVATE: "Activate",
    ARCHIVED: "Archived",
    DASHBOARD_TYPE: {
        CHART: "chart",
        TABLE: "table",
    },
    ORG_TYPE: {
        CERTIFIED: "Certified",
        DEACTIVATED: "Dectivated",
        REGISTERED: "Registred",
        RENEWING: "Renewing",
    },
    CHART_ID_LABELS: {
        MEASURE: "Measure ID",
    },
    VALIDATION_TYPE: "fileUpload",
    INITIATED: "INITIATED",
    PROCESSING: "PROCESSING",
    PROCESSED: "PROCESSED",
    PROCESSING_FAILED: "PROCESSING_FAILED",
    EMS_PROGRAM_NAME: "Emergency Medical Services(EMS)",
    GLOBAL_EXPORTS_WAIT_TIME: 5000,
    EXPORT_RESPONSE: {
        IN_PROGRESS: "IN_PROGRESS",
        SUCCESS: "SUCCESS",
        FAILED: "FAILED",
    },
    NO_AWARD: "No Award",
    EMS_AWARD_YEAR: "Award FY2023",
    EMS_EXPORT_YEAR: "2023",
    NOT_APPLICABLE: "N/A",
    CERTIFICATE_EXPIRES_ON: "Certificate expires on",
    CERTIFICATE_EXPIRED_ON: "Certificate expired on",
    AWARD_EXPIRES_ON: "Award expires on",
    AWARD_EXPIRED_ON: "Award expired on",
    HEART_FAILURE_PROGRAM_ID: 14,
    RENEW_CONFIRM_RESPONSE: {
        IN_PROGRESS: "IN_PROGRESS",
        SUCCESS: "SUCCESS",
        FAILED: "FAILED",
    },
    countriesToHideCertificates: [
        "MX",
        "ID",
        "MY",
        "TW",
        "TH",
        "VN",
        "IN",
        "JP",
    ],
    countriesToHideBrandingMaterials: ["SA", "MX"],
};

export default CONSTANTS;
