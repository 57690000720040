import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logError } from "utils/logger.utils";
import { BreadCrumb } from "components/BreadCrumb";
import JsPDF from "jspdf";
import axios from "axios";
import { CertificationWrapper } from "./styled";
import {
    getHospitalProgramCertificate,
    getAssetDetails,
} from "../../api/certificateAPI";
import Navigator from "../../components/Navigator";
import { getHospitalById } from "../../api/hospitalApi";
import { setPageLoadingStatus } from "../../components/Loader/loader.slice";
import { showToast } from "../../components/Toast/toast.slice";
import {
    localDateToYYYYMMDDFormatString,
    parseJwt,
    sleep,
    redirectionForErrorCode,
} from "../../common/utils";
import CONSTANTS from "../../common/constants";

interface certificateDetails {
    programCertificateId: number;
    programCertificateName: string;
    programCertificateFacilityName: string;
    programCertificateGrantedOn: string;
    programCertificateExpiryDate: string;
    programCertificateCity: string;
    programCertificateState: string;
    programCertificateCountry: string;
    programCertificatePath: string;
    brandingMaterials: Array<brandingMaterials>;
    certificateYear: string;
}

interface brandingMaterials {
    materialId: string;
    referenceId: string;
    isAllowed: boolean;
}

interface Props {
    isCorporation?: any;
}

const CertificationPage = ({ isCorporation }: Props) => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const location = useLocation();
    const [hospitalCertificateDetails, setHospitalCertificateDetails] =
        useState<certificateDetails>(Object);
    const [facilityName, setFacilityName] = useState<any>("");
    const [countryCode, setCountryCode] = useState<any>("");
    const [assets, setAssets] = useState<any>("");
    const [signedUrl, setSignedUrl] = useState<any>("");
    const [recallInd, setRecallInd] = useState<boolean>(false);
    const [referenceIdList, setReferenceIdList] = useState<string>("");
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [isCertificateLoaded, setIsCertificateLoaded] =
        useState<boolean>(false);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const programId = localStorage.getItem("selectedProgramId");
    const history = useHistory();
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;

    const getAssets = (referenceIdLists: string) => {
        getAssetDetails(referenceIdLists, true).then((result: any) => {
            if (
                result.statusCode == 200 &&
                Object.keys(result.data).length > 0
            ) {
                setAssets(result.data.assets);
                sleep(270000).then(() => {
                    setRecallInd(!recallInd);
                });
            }
        });
    };

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getHospitalProgramCertificate(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then((response: any) => {
                if (response.statusCode == 200) {
                    const { programCertficate } = response.data;
                    setHospitalCertificateDetails(programCertficate);
                    setIsCertificateLoaded(true);
                    setSignedUrl(programCertficate.programCertificatePath);
                    getHospitalById(params.hospitalId)
                        .then((result: any) => {
                            if (result.statusCode == 200) {
                                setFacilityName(
                                    result.data.hospital.facilityName
                                );
                                setCountryCode(
                                    result.data.hospital.countryCode
                                );
                                const elementsArray: any = [];
                                elementsArray.push(
                                    {
                                        label: "Home",
                                        returnPath: {
                                            pathname:
                                                CONSTANTS.ROUTES.USER_DASHBOARD,
                                            state: {
                                                pageNum:
                                                    location?.state?.pageNum,
                                                search: location?.state?.search,
                                            },
                                        },
                                    },
                                    {
                                        label: result.data.hospital
                                            .facilityName,
                                        returnPath: {
                                            pathname:
                                                programId !==
                                                CONSTANTS?.EMS_PROGRAM_ID
                                                    ? "/dashboard"
                                                    : `/measures/organization/${params.hospitalId}/programs/${params.programId}/categories/${params.categoryId}`,
                                            state: {
                                                pageNum:
                                                    location?.state?.pageNum,
                                                search: location?.state?.search,
                                            },
                                        },
                                    },
                                    {
                                        label:
                                            programId !==
                                            CONSTANTS?.EMS_PROGRAM_ID
                                                ? "Certificate"
                                                : "Award",
                                        returnPath: "",
                                    }
                                );
                                setBreadcrumbItems(elementsArray);
                            }
                        })
                        .catch((error: any) => {
                            dispatch(
                                setPageLoadingStatus({
                                    isPageLoading: false,
                                })
                            );
                            logError(error);
                        });
                    if (Object.keys(programCertficate).length > 0) {
                        if (programCertficate.brandingMaterials.length > 0) {
                            const materialReferenceIds = new Array(0);
                            programCertficate.brandingMaterials.map(
                                (brandingMaterial: any) => {
                                    materialReferenceIds.push(
                                        brandingMaterial.referenceId
                                    );
                                }
                            );
                            const referenceIdLists =
                                materialReferenceIds.join(",");
                            setReferenceIdList(materialReferenceIds.join(","));
                            getAssets(referenceIdLists);
                        }
                    }
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((err) => {
                if (isCorporation) {
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        err,
                        history,
                        2000
                    );
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                dispatch(showToast(err));
            });
    }, []);

    useEffect(() => {
        referenceIdList &&
            getHospitalProgramCertificate(
                params.hospitalId,
                params.programId,
                params.categoryId
            )
                .then((response: any) => {
                    if (response.statusCode == 200) {
                        const { programCertficate } = response.data;
                        setSignedUrl(programCertficate.programCertificatePath);
                        if (Object.keys(programCertficate).length > 0) {
                            getAssets(referenceIdList);
                        }
                    }
                })
                .catch((err) => {
                    dispatch(showToast(err));
                });
    }, [recallInd]);

    const createCanvas = async () => {
        const pdfDocument = new JsPDF("l", "in", [11, 8.5], true);
        getHospitalProgramCertificate(
            params.hospitalId,
            params.programId,
            params.categoryId
        ).then(async (response) => {
            const { programCertficate } = response.data;
            try {
                const axiosResponse = await axios({
                    method: "get",
                    url: programCertficate.programCertificatePath,
                    responseType: "arraybuffer",
                });
                if (axiosResponse.data) {
                    const imageWidth = 11;
                    const imageHeight = 8.5;
                    const leftMargin = 0;
                    const rightMargin = 0;
                    const awardName = programCertficate.programCertificatePath
                        .slice(
                            programCertficate.programCertificatePath.lastIndexOf(
                                "/"
                            ) + 1,
                            programCertficate.programCertificatePath.indexOf(
                                "?"
                            )
                        )
                        .slice(0, -4);
                    const imageData = new Uint8Array(axiosResponse.data);
                    const centerX =
                        (pdfDocument.internal.pageSize.width -
                            imageWidth -
                            leftMargin -
                            rightMargin) /
                            2 +
                        leftMargin;
                    pdfDocument.addImage(
                        imageData,
                        "PNG",
                        centerX,
                        0,
                        imageWidth,
                        imageHeight
                    );
                    pdfDocument.save(`${awardName}.pdf`);
                }
            } catch {
                const toast = {
                    message: "Error in downloading award",
                };

                dispatch(showToast(toast));
            }
        });
    };

    return (
        <CertificationWrapper>
            {!isCorporation ? <Navigator tabName="certificate" /> : " "}
            {isCertificateLoaded ? (
                Object.keys(hospitalCertificateDetails).length === 0 ||
                hospitalCertificateDetails.programCertificateName ===
                    CONSTANTS.NO_AWARD ||
                hospitalCertificateDetails.programCertificateName ===
                    CONSTANTS.NOT_APPLICABLE ? (
                    <div className="container">
                        {isEUP && (
                            <div className="mb-n4">
                                <BreadCrumb breadcrumbItems={breadcrumbItems} />
                            </div>
                        )}
                        {isCorporation ? (
                            <h2 className="h3 main-pg-heading">Certificate</h2>
                        ) : (
                            <h1 className="h3 main-pg-heading">
                                {programId !== CONSTANTS?.EMS_PROGRAM_ID
                                    ? "Certificate"
                                    : "Award"}
                            </h1>
                        )}
                        <div className="text-center py-5 my-5">
                            <img
                                src="/images/nocertificate.svg"
                                alt={`No ${
                                    programId !== CONSTANTS?.EMS_PROGRAM_ID
                                        ? "Certificate"
                                        : "Award for E M S"
                                }`}
                            />
                            <div className="my-5 h6 font-600">
                                {programId !== CONSTANTS?.EMS_PROGRAM_ID
                                    ? "No Certificate Awarded"
                                    : CONSTANTS.NO_AWARD}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        {isEUP && (
                            <div className="mb-n4">
                                <BreadCrumb breadcrumbItems={breadcrumbItems} />
                            </div>
                        )}
                        {programId === CONSTANTS.EMS_PROGRAM_ID &&
                        !isCorporation ? (
                            <h1 className="h3 main-pg-heading">Award</h1>
                        ) : (
                            <h2 className="h3 main-pg-heading">Certificate</h2>
                        )}
                        <div className="d-md-flex">
                            <aside className="cert-info mb-5">
                                <h2 className="h3 font-600 cert-info-facility mb-2">
                                    {facilityName}
                                </h2>
                                <div className="cert-info-detail">
                                    <div className="cert-info-item">
                                        <div className="d-inline-block d-md-block pr-2">
                                            Registered Program(s):
                                        </div>
                                        <div className="font-bold d-inline-block d-md-block">
                                            {programId ===
                                            CONSTANTS.EMS_PROGRAM_ID ? (
                                                <>
                                                    Emergency Medical Services
                                                    (EMS){" "}
                                                    <span aria-hidden="true">
                                                        -
                                                    </span>
                                                    <span className="sr-only">
                                                        hyphen
                                                    </span>
                                                    <span className="ml-1">
                                                        {
                                                            hospitalCertificateDetails.certificateYear
                                                        }
                                                        <span className="ml-1 mr-1">
                                                            {
                                                                hospitalCertificateDetails.programCertificateName
                                                            }
                                                        </span>
                                                    </span>
                                                    Award
                                                </>
                                            ) : (
                                                hospitalCertificateDetails.programCertificateName
                                            )}
                                        </div>
                                    </div>
                                    <div className="cert-info-item">
                                        Granted on:{" "}
                                        <span className="font-bold">
                                            {localDateToYYYYMMDDFormatString(
                                                hospitalCertificateDetails.programCertificateGrantedOn
                                            )}
                                        </span>
                                    </div>
                                    {programId !== CONSTANTS.EMS_PROGRAM_ID && (
                                        <div className="cert-info-item">
                                            Expires on:{" "}
                                            <span className="font-bold">
                                                {localDateToYYYYMMDDFormatString(
                                                    hospitalCertificateDetails.programCertificateExpiryDate
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </aside>
                            <section className="cert-container">
                                <h3
                                    className="h4 font-600 cert-prm"
                                    aria-label={`${
                                        programId === CONSTANTS.EMS_PROGRAM_ID
                                            ? `Emergency Medical Services (EMS) hyphen ${hospitalCertificateDetails.certificateYear} ${hospitalCertificateDetails.programCertificateName} award`
                                            : `${hospitalCertificateDetails.programCertificateName}`
                                    }`}
                                >
                                    {programId === CONSTANTS.EMS_PROGRAM_ID ? (
                                        <>
                                            Emergency Medical Services (EMS)
                                            <span aria-label="hyphen"> - </span>
                                            <span className="ml-2 mr-1">
                                                {
                                                    hospitalCertificateDetails.certificateYear
                                                }
                                                <span className="ml-2 mr-1">
                                                    {
                                                        hospitalCertificateDetails.programCertificateName
                                                    }
                                                </span>
                                            </span>
                                            Award
                                        </>
                                    ) : (
                                        hospitalCertificateDetails.programCertificateName
                                    )}
                                </h3>
                                <p>
                                    On behalf of the American Heart Association
                                    please accept our congratulations for
                                    achieving this well-deserved recognition.
                                    This recognition is a testament to your hard
                                    work and dedication in serving your
                                    population and your community. Below, you
                                    will find a marketing toolkit for your
                                    program. These resources can be used to
                                    share your achievement publicly.
                                </p>
                                <img
                                    src={signedUrl}
                                    alt={`
                                    ${
                                        (params.programId === 14 &&
                                            params.categoryId === 5) ||
                                        params.programId ===
                                            CONSTANTS.EMS_PROGRAM_ID
                                            ? "award for E M S"
                                            : ""
                                    }
                                    ${
                                        hospitalCertificateDetails.programCertificateName
                                    }`}
                                    className="cert-img mt-3"
                                />
                                {assets && assets.length > 0 && (
                                    <div>
                                        <h4 className="h5 font-600 mt-5 mb-4">
                                            Downloads
                                        </h4>
                                        <div className="cert-tags">
                                            {assets.length
                                                ? assets.map(
                                                      (assetDetail: any) => {
                                                          if (
                                                              assetDetail.assetName ===
                                                                  "Award Certificate" &&
                                                              programId !==
                                                                  CONSTANTS.EMS_PROGRAM_ID
                                                          ) {
                                                              return (
                                                                  <a
                                                                      key={
                                                                          assetDetail.assetId
                                                                      }
                                                                      href={
                                                                          signedUrl
                                                                      }
                                                                      className="cert-tag-btn"
                                                                  >
                                                                      Certificate
                                                                  </a>
                                                              );
                                                          }
                                                          if (
                                                              assetDetail.assetName ===
                                                                  "Award Certificate" &&
                                                              programId ===
                                                                  CONSTANTS.EMS_PROGRAM_ID
                                                          ) {
                                                              return (
                                                                  <button
                                                                      key={
                                                                          assetDetail.assetId
                                                                      }
                                                                      onClick={() =>
                                                                          createCanvas()
                                                                      }
                                                                      className="cert-tag-btn"
                                                                      role="link"
                                                                  >
                                                                      Award
                                                                  </button>
                                                              );
                                                          }
                                                          if (
                                                              CONSTANTS.countriesToHideBrandingMaterials.includes(
                                                                  countryCode
                                                              ) &&
                                                              Number(
                                                                  programId
                                                              ) === 17
                                                          ) {
                                                              return null;
                                                          }
                                                          return (
                                                              <a
                                                                  key={
                                                                      assetDetail.assetId
                                                                  }
                                                                  href={
                                                                      assetDetail.presignedUrl
                                                                  }
                                                                  className="cert-tag-btn"
                                                              >
                                                                  {
                                                                      assetDetail.assetName
                                                                  }
                                                              </a>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                    </div>
                )
            ) : null}
        </CertificationWrapper>
    );
};

export default CertificationPage;
